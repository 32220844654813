import React from 'react';
import styled, { withTheme } from 'styled-components';
import BaseButton from './BaseButton';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const Wrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button2 = ({ theme, children, height, width, borderRadius, onClick, isDarkMode }) => (
  <BaseButton
    background={isDarkMode ? theme.secondary.white : theme.primary.charcoal}
    hoverBackground={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
    borderHoverColor={isDarkMode ? theme.secondary.white : theme.primary.charcoal}
    color={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
    hoverColor={isDarkMode ? theme.secondary.white : theme.primary.charcoal}
    height={height}
    width={width}
    onClick={onClick}
    borderRadius={borderRadius}
    buttonType={button_types.button}
  >
    <Wrapper>{children}</Wrapper>
  </BaseButton>
);

export default withTheme(Button2);
