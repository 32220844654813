import React from 'react';
import styled, { withTheme, css } from 'styled-components';

const isDarkBg = css`
  svg {
    background: #2b2927;
    border-radius: 9px;
    .border-color-app-store {
      fill: #2b2927;
    }
  }
`;

const Wrapper = styled.div`
  g {
    fill: ${(props) => (props.isDarkMode ? props.theme.secondary.white : props.theme.primary.charcoal)};
  }
  ${(props) => (props.isDarkBg ? isDarkBg : '')};
`;

function AppStore({ isDarkMode, height, width, isDarkBg }) {
  return (
    <Wrapper isDarkMode={isDarkMode} isDarkBg={isDarkBg}>
      <svg width={width} height={height} viewBox="0 0 205 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            className="border-color-app-store"
            d="M196.667 1.17426C200.169 1.17426 203.019 3.93509 203.019 7.32796V51.296C203.019 54.6889 200.169 57.4497 196.667 57.4497H7.5641C4.06192 57.4497 1.21215 54.6889 1.21215 51.296V7.32796C1.21215 3.93509 4.06192 1.17426 7.5641 1.17426H196.667ZM196.667 -5.01847e-05H7.5641C3.40574 -5.01847e-05 0 3.29938 0 7.32796V51.296C0 55.3246 3.40574 58.624 7.5641 58.624H196.667C200.825 58.624 204.231 55.3246 204.231 51.296V7.32796C204.231 3.29938 200.825 -5.01847e-05 196.667 -5.01847e-05Z"
          />
          <path d="M45.578 28.9951C45.5341 24.2715 49.5703 21.9734 49.7549 21.8665C47.469 18.6377 43.926 18.1966 42.6809 18.1614C39.7052 17.858 36.8187 19.8864 35.3029 19.8864C33.7568 19.8864 31.4225 18.1907 28.9067 18.2406C25.6692 18.2889 22.6406 20.1048 20.9795 22.9246C17.5515 28.6742 20.1081 37.1234 23.3925 41.7708C25.0354 44.0469 26.9551 46.5882 29.4679 46.4988C31.9263 46.4006 32.8446 44.9805 35.8112 44.9805C38.7506 44.9805 39.6129 46.4988 42.1756 46.4417C44.814 46.4006 46.4751 44.1553 48.0605 41.8587C49.9591 39.25 50.7216 36.6808 50.7518 36.5489C50.6898 36.5283 45.6279 34.6568 45.578 28.9951Z" />
          <path d="M40.7369 15.1042C42.0591 13.5023 42.9638 11.3229 42.7127 9.11133C40.799 9.1934 38.4057 10.3937 37.0275 11.9605C35.8082 13.3411 34.7189 15.6039 35.0003 17.732C37.15 17.8874 39.3572 16.6812 40.7369 15.1042Z" />
          <path d="M81.1547 46.1737H77.7191L75.8372 40.4446H69.2957L67.503 46.1737H64.1582L70.6391 26.6694H74.642L81.1547 46.1737ZM75.2699 38.041L73.5679 32.9481C73.3879 32.4278 73.0506 31.2025 72.5528 29.2738H72.4923C72.2941 30.1033 71.9749 31.3286 71.5362 32.9481L69.8646 38.041H75.2699Z" />
          <path d="M97.8218 38.9671C97.8218 41.3589 97.1546 43.2496 95.8203 44.6375C94.6252 45.873 93.1411 46.49 91.3696 46.49C89.4574 46.49 88.0837 45.8246 87.2472 44.4939H87.1866V51.9025H83.9613V36.7379C83.9613 35.2342 83.9205 33.6909 83.8418 32.1081H86.6783L86.8584 34.3372H86.9189C87.9945 32.6577 89.6268 31.8193 91.8174 31.8193C93.5299 31.8193 94.9595 32.4745 96.1032 33.7862C97.2499 35.0994 97.8218 36.8258 97.8218 38.9671ZM94.5359 39.0814C94.5359 37.7125 94.2182 36.584 93.5798 35.6959C92.8824 34.7696 91.946 34.3065 90.772 34.3065C89.9763 34.3065 89.2532 34.5644 88.6072 35.073C87.9597 35.5859 87.5361 36.2557 87.3379 37.0852C87.2381 37.4722 87.1882 37.7887 87.1882 38.0379V40.3828C87.1882 41.4058 87.5119 42.2691 88.1594 42.974C88.8069 43.679 89.648 44.0307 90.6828 44.0307C91.8976 44.0307 92.8431 43.5764 93.5193 42.6707C94.1971 41.7634 94.5359 40.5675 94.5359 39.0814Z" />
          <path d="M114.518 38.9671C114.518 41.3589 113.851 43.2496 112.516 44.6375C111.322 45.873 109.838 46.49 108.066 46.49C106.154 46.49 104.78 45.8246 103.945 44.4939H103.885V51.9025H100.66V36.7379C100.66 35.2342 100.619 33.6909 100.54 32.1081H103.377L103.557 34.3372H103.617C104.691 32.6577 106.324 31.8193 108.516 31.8193C110.227 31.8193 111.656 32.4745 112.803 33.7862C113.945 35.0994 114.518 36.8258 114.518 38.9671ZM111.233 39.0814C111.233 37.7125 110.913 36.584 110.275 35.6959C109.578 34.7696 108.644 34.3065 107.469 34.3065C106.672 34.3065 105.95 34.5644 105.302 35.073C104.655 35.5859 104.233 36.2557 104.035 37.0852C103.936 37.4722 103.885 37.7887 103.885 38.0379V40.3828C103.885 41.4058 104.209 42.2691 104.853 42.974C105.501 43.6775 106.342 44.0307 107.38 44.0307C108.594 44.0307 109.54 43.5764 110.216 42.6707C110.894 41.7634 111.233 40.5675 111.233 39.0814Z" />
          <path d="M133.186 40.7022C133.186 42.3613 132.592 43.7111 131.398 44.7532C130.086 45.8919 128.26 46.4606 125.914 46.4606C123.748 46.4606 122.011 46.0561 120.696 45.2456L121.444 42.6412C122.86 43.4708 124.413 43.887 126.106 43.887C127.321 43.887 128.266 43.6202 128.946 43.0897C129.622 42.5592 129.959 41.8469 129.959 40.9587C129.959 40.1673 129.681 39.5004 129.123 38.9596C128.567 38.4188 127.64 37.9161 126.345 37.4515C122.82 36.1779 121.059 34.3122 121.059 31.8588C121.059 30.2554 121.677 28.9408 122.913 27.9178C124.144 26.8933 125.787 26.3818 127.841 26.3818C129.673 26.3818 131.195 26.6911 132.41 27.3081L131.604 29.8553C130.469 29.2573 129.186 28.9584 127.751 28.9584C126.616 28.9584 125.729 29.2295 125.094 29.7688C124.557 30.251 124.288 30.8387 124.288 31.5349C124.288 32.3058 124.595 32.9433 125.212 33.4446C125.749 33.9077 126.725 34.4089 128.141 34.9497C129.873 35.6254 131.145 36.4153 131.964 37.3211C132.779 38.2239 133.186 39.3539 133.186 40.7022Z" />
          <path d="M143.851 34.4527H140.296V41.281C140.296 43.0177 140.922 43.8853 142.178 43.8853C142.754 43.8853 143.232 43.837 143.61 43.7403L143.699 46.1131C143.064 46.3432 142.227 46.4589 141.191 46.4589C139.917 46.4589 138.922 46.0823 138.203 45.3304C137.488 44.5771 137.128 43.3138 137.128 41.5389V34.4498H135.01V32.1048H137.128V29.5298L140.296 28.6035V32.1048H143.851V34.4527Z" />
          <path d="M159.891 39.0237C159.891 41.1855 159.252 42.9604 157.979 44.3483C156.643 45.7772 154.87 46.4895 152.66 46.4895C150.53 46.4895 148.834 45.8051 147.569 44.4362C146.304 43.0673 145.672 41.3394 145.672 39.2568C145.672 37.0774 146.322 35.2923 147.628 33.9044C148.93 32.515 150.688 31.8203 152.899 31.8203C155.029 31.8203 156.743 32.5047 158.036 33.8751C159.274 35.2044 159.891 36.9206 159.891 39.0237ZM156.545 39.1249C156.545 37.8278 156.259 36.7154 155.679 35.7877C155.003 34.665 154.036 34.1052 152.784 34.1052C151.487 34.1052 150.502 34.6665 149.826 35.7877C149.247 36.7169 148.961 37.8469 148.961 39.1835C148.961 40.4806 149.247 41.5929 149.826 42.5192C150.523 43.6419 151.498 44.2017 152.755 44.2017C153.986 44.2017 154.953 43.6301 155.65 42.4899C156.245 41.5446 156.545 40.4205 156.545 39.1249Z" />
          <path d="M170.374 34.8561C170.055 34.7989 169.715 34.7696 169.358 34.7696C168.223 34.7696 167.346 35.1844 166.728 36.0154C166.191 36.7482 165.922 37.6744 165.922 38.7927V46.172H162.698L162.728 36.5371C162.728 34.9162 162.688 33.4403 162.607 32.1095H165.417L165.535 34.8004H165.624C165.964 33.8756 166.501 33.131 167.237 32.5727C167.955 32.07 168.731 31.8193 169.568 31.8193C169.866 31.8193 170.135 31.8399 170.374 31.8765V34.8561Z" />
          <path d="M184.8 38.4742C184.8 39.034 184.762 39.5059 184.682 39.8914H175.006C175.044 41.2808 175.511 42.3433 176.41 43.0761C177.225 43.7313 178.28 44.0596 179.574 44.0596C181.007 44.0596 182.314 43.8383 183.49 43.3942L183.995 45.5633C182.621 46.1437 181 46.4324 179.128 46.4324C176.877 46.4324 175.11 45.7904 173.824 44.508C172.541 43.2256 171.898 41.5036 171.898 39.3433C171.898 37.2225 172.496 35.4565 173.693 34.048C174.945 32.5443 176.638 31.7925 178.768 31.7925C180.86 31.7925 182.444 32.5443 183.52 34.048C184.372 35.2425 184.8 36.7198 184.8 38.4742ZM181.724 37.6637C181.745 36.7374 181.535 35.9372 181.098 35.2616C180.54 34.3925 179.682 33.9586 178.528 33.9586C177.473 33.9586 176.615 34.3822 175.96 35.2322C175.423 35.9079 175.104 36.7184 175.006 37.6622H181.724V37.6637Z" />
          <path d="M74.2041 14.6694C74.2041 16.3944 73.6701 17.6929 72.6036 18.565C71.6157 19.3696 70.2118 19.7726 68.3934 19.7726C67.4917 19.7726 66.7202 19.7345 66.0742 19.6583V10.233C66.9169 10.1011 67.8246 10.0337 68.8049 10.0337C70.537 10.0337 71.8426 10.3986 72.7231 11.1285C73.7094 11.9536 74.2041 13.1334 74.2041 14.6694ZM72.5325 14.7119C72.5325 13.5936 72.2269 12.7363 71.6157 12.1383C71.0045 11.5418 70.1119 11.2428 68.9365 11.2428C68.4372 11.2428 68.0121 11.2751 67.6597 11.3425V18.5078C67.8548 18.5371 68.2118 18.5503 68.7307 18.5503C69.944 18.5503 70.8805 18.2235 71.54 17.5698C72.1996 16.9162 72.5325 15.9635 72.5325 14.7119Z" />
          <path d="M83.0677 16.1752C83.0677 17.2377 82.7545 18.1083 82.1282 18.7913C81.4717 19.4933 80.6018 19.8436 79.5156 19.8436C78.4687 19.8436 77.6352 19.5079 77.0134 18.8338C76.3931 18.1611 76.083 17.3125 76.083 16.2895C76.083 15.2196 76.4022 14.3417 77.0436 13.6602C77.6851 12.9787 78.5474 12.6372 79.6336 12.6372C80.6805 12.6372 81.5216 12.9728 82.1585 13.6455C82.7636 14.2992 83.0677 15.1434 83.0677 16.1752ZM81.4233 16.225C81.4233 15.5875 81.2811 15.0408 80.9982 14.585C80.6653 14.0339 80.1918 13.7584 79.5761 13.7584C78.9392 13.7584 78.4551 14.0339 78.1223 14.585C77.8379 15.0408 77.6972 15.5963 77.6972 16.2528C77.6972 16.8904 77.8394 17.4371 78.1223 17.8929C78.4657 18.4439 78.9438 18.7195 79.561 18.7195C80.1661 18.7195 80.6411 18.4395 80.983 17.8782C81.2765 17.4136 81.4233 16.8625 81.4233 16.225Z" />
          <path d="M94.9527 12.7778L92.7213 19.6867H91.269L90.3446 16.6866C90.1101 15.9377 89.9195 15.1931 89.7713 14.4545H89.7425C89.6049 15.2137 89.4142 15.9567 89.1692 16.6866L88.1873 19.6867H86.7184L84.6201 12.7778H86.2494L87.0558 16.0622C87.2509 16.839 87.4113 17.5791 87.5399 18.2797H87.5686C87.6866 17.7023 87.8818 16.9665 88.1571 16.0769L89.1692 12.7793H90.4611L91.4308 16.0066C91.6653 16.7936 91.8559 17.5513 92.0027 18.2812H92.0465C92.154 17.5704 92.3158 16.8126 92.5307 16.0066L93.396 12.7793H94.9527V12.7778Z" />
          <path d="M103.172 19.6872H101.586V15.7301C101.586 14.5107 101.108 13.901 100.149 13.901C99.6788 13.901 99.299 14.0681 99.004 14.4037C98.7121 14.7394 98.5638 15.1351 98.5638 15.588V19.6858H96.9784V14.7526C96.9784 14.1458 96.9587 13.4877 96.9209 12.7755H98.3142L98.3883 13.8556H98.4322C98.6168 13.52 98.8921 13.243 99.2537 13.0217C99.6833 12.7637 100.164 12.6333 100.691 12.6333C101.356 12.6333 101.91 12.8414 102.35 13.2591C102.898 13.7706 103.172 14.5342 103.172 15.5484V19.6872Z" />
          <path d="M107.544 19.6873H105.96V9.6084H107.544V19.6873Z" />
          <path d="M116.878 16.1752C116.878 17.2377 116.565 18.1083 115.939 18.7913C115.282 19.4933 114.411 19.8436 113.326 19.8436C112.278 19.8436 111.444 19.5079 110.824 18.8338C110.204 18.1611 109.894 17.3125 109.894 16.2895C109.894 15.2196 110.213 14.3417 110.854 13.6602C111.496 12.9787 112.358 12.6372 113.443 12.6372C114.491 12.6372 115.331 12.9728 115.969 13.6455C116.574 14.2992 116.878 15.1434 116.878 16.1752ZM115.232 16.225C115.232 15.5875 115.09 15.0408 114.807 14.585C114.476 14.0339 114.001 13.7584 113.387 13.7584C112.748 13.7584 112.264 14.0339 111.933 14.585C111.648 15.0408 111.508 15.5963 111.508 16.2528C111.508 16.8904 111.65 17.4371 111.933 17.8929C112.276 18.4439 112.754 18.7195 113.372 18.7195C113.977 18.7195 114.45 18.4395 114.792 17.8782C115.087 17.4136 115.232 16.8625 115.232 16.225Z" />
          <path d="M124.551 19.6867H123.127L123.009 18.8909H122.965C122.478 19.5255 121.784 19.8436 120.882 19.8436C120.209 19.8436 119.664 19.634 119.255 19.2178C118.882 18.8396 118.696 18.3692 118.696 17.8108C118.696 16.9666 119.059 16.3232 119.79 15.8777C120.519 15.4321 121.545 15.2137 122.866 15.224V15.095C122.866 14.1849 122.372 13.7305 121.385 13.7305C120.681 13.7305 120.061 13.902 119.525 14.242L119.203 13.2337C119.866 12.8365 120.684 12.6372 121.649 12.6372C123.513 12.6372 124.448 13.5898 124.448 15.4951V18.0394C124.448 18.7297 124.483 19.2793 124.551 19.6867ZM122.905 17.3125V16.247C121.156 16.2177 120.282 16.6823 120.282 17.6393C120.282 17.9998 120.382 18.2695 120.586 18.4498C120.79 18.6301 121.05 18.7195 121.36 18.7195C121.708 18.7195 122.034 18.6125 122.33 18.4C122.628 18.186 122.811 17.9148 122.879 17.5821C122.896 17.5074 122.905 17.4165 122.905 17.3125Z" />
          <path d="M133.56 19.6873H132.153L132.079 18.5779H132.035C131.585 19.4221 130.82 19.8442 129.744 19.8442C128.885 19.8442 128.169 19.5173 127.602 18.8637C127.035 18.21 126.752 17.3614 126.752 16.3194C126.752 15.2011 127.059 14.2954 127.676 13.6036C128.274 12.9588 129.006 12.6363 129.877 12.6363C130.835 12.6363 131.505 12.9485 131.886 13.5743H131.917V9.6084H133.504V17.826C133.504 18.4987 133.522 19.1187 133.56 19.6873ZM131.917 16.7737V15.6218C131.917 15.4224 131.902 15.2612 131.873 15.1381C131.784 14.7688 131.591 14.4581 131.299 14.2075C131.004 13.9568 130.649 13.8308 130.239 13.8308C129.647 13.8308 129.185 14.058 128.844 14.5138C128.507 14.9696 128.336 15.5514 128.336 16.2622C128.336 16.9452 128.498 17.4992 128.823 17.9257C129.166 18.38 129.629 18.6072 130.209 18.6072C130.729 18.6072 131.145 18.4181 131.461 18.0385C131.767 17.6883 131.917 17.2662 131.917 16.7737Z" />
          <path d="M147.119 16.1752C147.119 17.2377 146.806 18.1083 146.18 18.7913C145.523 19.4933 144.655 19.8436 143.567 19.8436C142.522 19.8436 141.688 19.5079 141.065 18.8338C140.445 18.1611 140.135 17.3125 140.135 16.2895C140.135 15.2196 140.454 14.3417 141.095 13.6602C141.737 12.9787 142.599 12.6372 143.687 12.6372C144.732 12.6372 145.575 12.9728 146.21 13.6455C146.815 14.2992 147.119 15.1434 147.119 16.1752ZM145.477 16.225C145.477 15.5875 145.334 15.0408 145.051 14.585C144.717 14.0339 144.245 13.7584 143.628 13.7584C142.992 13.7584 142.508 14.0339 142.174 14.585C141.89 15.0408 141.749 15.5963 141.749 16.2528C141.749 16.8904 141.891 17.4371 142.174 17.8929C142.517 18.4439 142.996 18.7195 143.613 18.7195C144.218 18.7195 144.694 18.4395 145.036 17.8782C145.328 17.4136 145.477 16.8625 145.477 16.225Z" />
          <path d="M155.644 19.6872H154.06V15.7301C154.06 14.5107 153.582 13.901 152.621 13.901C152.151 13.901 151.771 14.0681 151.478 14.4037C151.184 14.7394 151.037 15.1351 151.037 15.588V19.6858H149.451V14.7526C149.451 14.1458 149.432 13.4877 149.395 12.7755H150.786L150.86 13.8556H150.904C151.09 13.52 151.366 13.243 151.726 13.0217C152.157 12.7637 152.637 12.6333 153.164 12.6333C153.829 12.6333 154.382 12.8414 154.823 13.2591C155.372 13.7706 155.644 14.5342 155.644 15.5484V19.6872Z" />
          <path d="M166.314 13.9292H164.568V17.2854C164.568 18.1384 164.878 18.5649 165.493 18.5649C165.777 18.5649 166.013 18.5415 166.199 18.4931L166.24 19.6583C165.927 19.7726 165.515 19.8297 165.008 19.8297C164.382 19.8297 163.895 19.6451 163.543 19.2757C163.189 18.9064 163.013 18.285 163.013 17.413V13.9292H161.971V12.7787H163.013V11.5124L164.567 11.0581V12.7773H166.312V13.9292H166.314Z" />
          <path d="M174.707 19.6873H173.12V15.7595C173.12 14.5211 172.642 13.9011 171.685 13.9011C170.949 13.9011 170.447 14.2602 170.172 14.9784C170.125 15.1293 170.098 15.314 170.098 15.5309V19.6859H168.514V9.6084H170.098V13.7722H170.128C170.627 13.0145 171.343 12.6363 172.27 12.6363C172.927 12.6363 173.47 12.8444 173.901 13.2621C174.438 13.7824 174.707 14.5563 174.707 15.5793V19.6873Z" />
          <path d="M183.365 15.906C183.365 16.1815 183.343 16.4131 183.306 16.6022H178.551C178.572 17.2851 178.799 17.8054 179.239 18.166C179.642 18.4884 180.16 18.6496 180.796 18.6496C181.499 18.6496 182.141 18.5411 182.719 18.3228L182.967 19.3897C182.291 19.6741 181.495 19.8162 180.573 19.8162C179.469 19.8162 178.599 19.5011 177.97 18.8709C177.338 18.2407 177.024 17.395 177.024 16.3339C177.024 15.2919 177.316 14.4243 177.905 13.7325C178.519 12.9938 179.35 12.6245 180.398 12.6245C181.424 12.6245 182.203 12.9938 182.729 13.7325C183.154 14.3187 183.365 15.0442 183.365 15.906ZM181.852 15.5088C181.864 15.053 181.76 14.6602 181.545 14.329C181.269 13.9025 180.85 13.6885 180.283 13.6885C179.766 13.6885 179.344 13.8967 179.021 14.3144C178.758 14.647 178.602 15.0442 178.551 15.5088H181.852Z" />
        </g>
      </svg>
    </Wrapper>
  );
}

export default withTheme(AppStore);
