import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  g {
    fill: ${(props) =>
      props.isDarkMode
        ? props.theme.secondary.white
        : props.theme.primary.charcoal};
  }
`;

function LogoShort({ isDarkMode, height, width }) {
  return (
    <Wrapper isDarkMode={isDarkMode}>
      <svg width={width} height={height} viewBox={`0 0 59 51`}>
        <g>
          <path d="M31.3589 51C31.0356 49.6297 29.8906 48.3508 28.5032 46.7457C24.7719 42.4522 20.0169 31.4378 32.4096 24.4821C43.3205 18.3485 54.6895 10.8969 59 0L27.6546 0C27.9779 1.37027 29.1228 2.64918 30.5103 4.25435C34.2416 8.54785 38.9966 19.5622 26.6039 26.5179C15.6795 32.6515 4.3105 40.1031 0 51L31.3589 51Z" />
        </g>
      </svg>
    </Wrapper>
  );
}

export default LogoShort;
