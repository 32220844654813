import React from 'react';
import styled, { withTheme } from 'styled-components';
import BaseButton from './BaseButton';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const Wrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button3 = ({ theme, children, width, onClick }) => (
  <BaseButton
    background={'transparent'}
    hoverBackground={theme.primary.charcoal}
    borderHoverColor={theme.primary.charcoal}
    color={theme.primary.charcoal}
    hoverColor={theme.secondary.white}
    borderColor={theme.primary.charcoal}
    width={width}
    onClick={onClick}
    buttonType={button_types.button}
  >
    <Wrapper>{children}</Wrapper>
  </BaseButton>
);

export default withTheme(Button3);
