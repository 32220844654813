import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';
import LogoFull from '@components/Images/LogoFull';
import Hamburger from '@components/Images/Hamburger';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Button2 } from '@components/Buttons';
import { Section } from '@components/Layout';
import { Eyebrow, H6 } from '@components/Text';
import { WEB_URL } from '../../config';
import { TrackingEvents } from '../../tracking';
import { PATHS } from '@constants/paths';
import { ElementPositionContext } from '../../context';
import { enums } from '@lib/nvstr-utils.es';
const { node_location } = enums;

const NavItem = styled(Column)`
  width: 84px;
  padding: 10px 5px;
  text-align: center;
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  svg {
    width: 180px;
  }

  @media (max-width: 400px) {
    svg {
      width: 150px;
    }
  }
`;

const A = styled.a`
  display: flex;
  text-decoration: none;
`;

const MobileMenu = ({ isDarkMode = false, theme, hideMenu }) => {
  return (
    <Column position="relative" padding="0 0 20px 0" zIndex="10" width={'100vw'} height={'496px'}>
      <Link href="/" passHref={true} onClick={hideMenu}>
        <Row
          vAlign="center"
          padding="28px 0 28px 40px"
          cursor="pointer"
          border={`1px solid ${isDarkMode ? theme.secondary.white : theme.secondary.charcoal}`}
          background={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
          borderStyle="solid none solid none"
        >
          <H6 isLarge isDarkMode={isDarkMode}>
            Home
          </H6>
        </Row>
      </Link>
      <Link href="/about" passHref={true} onClick={hideMenu}>
        <Row
          vAlign="center"
          padding="28px 0 28px 40px"
          cursor="pointer"
          border={`1px solid ${isDarkMode ? theme.secondary.white : theme.secondary.charcoal}`}
          background={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
          borderStyle="none none solid none"
        >
          <H6 isLarge isDarkMode={isDarkMode}>
            About
          </H6>
        </Row>
      </Link>
      <Link href="/app/login" passHref={true} onClick={hideMenu}>
        <Row
          vAlign="center"
          padding="28px 0 28px 40px"
          cursor="pointer"
          border={`1px solid ${isDarkMode ? theme.secondary.white : theme.secondary.charcoal}`}
          background={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
          borderStyle="none none solid none"
        >
          <H6 isLarge isDarkMode={isDarkMode}>
            Log In
          </H6>
        </Row>
      </Link>
      <Link href="/app/signup" passHref={true} onClick={hideMenu}>
        <Row
          vAlign="center"
          padding="28px 0 28px 40px"
          cursor="pointer"
          border={`1px solid ${isDarkMode ? theme.secondary.white : theme.secondary.charcoal}`}
          background={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
          borderStyle="none none solid none"
        >
          <H6 isLarge isDarkMode={isDarkMode}>
            Get Started
          </H6>
        </Row>
      </Link>
    </Column>
  );
};

const HeaderMobile = ({ isDarkMode = false, theme, urlParams, onShowSignUpModal }) => {
  const [showHamburgerNav, setShowHamburgerNav] = React.useState(false);
  const onHideMenu = () => {
    setShowHamburgerNav(false);
  };

  return (
    <Row
      position="relative"
      vAlign="center"
      height="60px"
      padding="20px 0 20px 0"
      zIndex="4"
      background={isDarkMode ? theme.primary.charcoal : theme.secondary.white}
    >
      {showHamburgerNav ? (
        <Place position="absolute" top="60px" left="0" width="100vw" zIndex="5">
          <MobileMenu theme={theme} isDarkMode={isDarkMode} hideMenu={onHideMenu} />
        </Place>
      ) : null}
      <Column width="100%" hAlign="center" vAlign="center">
        <Row position="relative" width="100%" style={{ justifyContent: 'space-between' }}>
          <Column width="60px" vAlign="center">
            <button
              onClick={() => {
                setShowHamburgerNav(!showHamburgerNav);
              }}
            >
              <Column padding="4px 0 0 16px" height={'50px'} vAlign="center">
                <Hamburger width={27} isDarkMode={isDarkMode} />
              </Column>
            </button>
          </Column>
          <Column width="50px" hAlign="center" vAlign="center" padding="4px 0 0 0">
            <A href={PATHS.HOME + urlParams}>
              <LogoWrapper>
                <LogoFull isDarkMode={isDarkMode} />
              </LogoWrapper>
            </A>
          </Column>
          <Column width="112px" hAlign="center" vAlign="center">
            <Place>
              <Button2
                height={'36px'}
                width={'80px'}
                borderRadius="8px"
                isDarkMode={isDarkMode}
                onClick={onShowSignUpModal}
              >
                Sign Up
              </Button2>
            </Place>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

const HeaderFull = ({ isDarkMode = false, urlParams, onShowSignUpModal }) => {
  const handleGetStartedClick = () => {
    TrackingEvents.splash.clickGetStartedButton.send({
      Context: 'Header Nav',
    });
  };

  return (
    <Row position="relative" hAlign="center" vAlign="center" height="84px" padding="0px 40px 0 40px" zIndex="4">
      <Column width="210px" vAlign="center">
        <Link href="/" passHref={true}>
          <a>
            <LogoFull isDarkMode={isDarkMode} />
          </a>
        </Link>
      </Column>
      <Column width="calc(100% - 500px)" hAlign="center" vAlign="center">
        <Row width="250px" hAlign="center" position="relative" left="30px">
          <Link href="/about" passHref={true}>
            <NavItem width="50%">
              <Eyebrow isDarkMode={isDarkMode}>ABOUT</Eyebrow>
            </NavItem>
          </Link>
        </Row>
      </Column>
      <Column width="290px" vAlign="center">
        <Row>
          <A href={`${WEB_URL}/login${urlParams}`}>
            <NavItem vAlign="center">
              <Eyebrow isDarkMode={isDarkMode}>LOG IN</Eyebrow>
            </NavItem>
          </A>
          <NavItem vAlign="center" onClick={onShowSignUpModal}>
            <Button2 isDarkMode={isDarkMode} onClick={handleGetStartedClick}>
              GET STARTED
            </Button2>
          </NavItem>
        </Row>
      </Column>
    </Row>
  );
};

const HeaderMain = (props) => {
  const { theme, useTransparentHeader, isDarkMode } = props;
  const { isMobile } = theme;

  if (isMobile) {
    return <HeaderMobile {...props} />;
  }

  return (
    <ElementPositionContext.Provider value={{ elementPosition: node_location.header }}>
      <Section background={useTransparentHeader || !isDarkMode ? 'transparent' : theme.primary.charcoal}>
        <HeaderFull {...props} />
      </Section>
    </ElementPositionContext.Provider>
  );
};

export default withTheme(HeaderMain);
