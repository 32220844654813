import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  g {
    fill: ${(props) =>
      props.isDarkMode
        ? props.theme.secondary.white
        : props.theme.primary.charcoal};
  }
`;

function LogoFull({ isDarkMode }) {
  return (
    <Wrapper isDarkMode={isDarkMode}>
      <svg width={184} height={21} viewBox="0 0 184 21">
        <g fill="#1F1F1F" fillRule="nonzero">
          <path d="M45.674 4.201v16.542h-4.55V4.201h-8.723V0h21.982v4.201h-8.71zm29.118 7.304v.153c0 5.732-3.2 9.21-10.838 9.21-7.638 0-10.865-3.492-10.865-9.21v-.153c0-5.731 3.227-9.21 10.865-9.21 7.624 0 10.838 3.492 10.838 9.21zm-4.591.126v-.098c0-3.812-1.934-5.133-6.26-5.133-4.327 0-6.261 1.321-6.261 5.133v.098c0 3.812 1.934 5.133 6.26 5.133 4.327.014 6.26-1.307 6.26-5.133zm18.587-9.335c.64 0 .946.027 1.35.125v4.535c-3.103-.71-8.404-.556-8.404 2.337v11.45h-4.577V2.421h4.577v6.024c0-3.144 1.837-6.15 7.054-6.15zm8.25 5.954c0-3.005 1.836-5.954 7.331-5.954 5.273 0 8.348 2.518 8.348 7.373v11.074h-4.578V10.24c0-2.393-1.502-3.965-5.467-3.965-2.727 0-5.62.557-5.62 2.825v11.658h-4.578V2.421h4.577V8.25h-.014zm23.205.96h-4.633v-.098c0-4.354 3.59-6.816 9.642-6.816 6.775 0 9.766 2.03 9.766 6.942v11.505h-4.549v-4.535c0 1.878-1.962 4.66-7.61 4.66-5.217 0-8.097-1.224-8.097-4.813v-.223c0-5.398 6.97-5.273 15.707-6.51v-.029c0-2.142-1.113-3.158-5.398-3.158-3.534 0-4.842 1.016-4.842 3.005v.07h.014zm3.13 8.653c3.618 0 7.096-.375 7.096-2.462v-2.616c-8.473.946-11.116 1.405-11.116 3.033v.098c0 1.21.974 1.947 4.02 1.947zM154.606 0h4.55v20.743h-4.55V15.61c0 2.337-1.934 5.272-7.665 5.272-6.776 0-9.516-3.687-9.516-9.238v-.097c0-5.55 2.754-9.238 9.516-9.238 5.731 0 7.665 2.922 7.665 5.273V0zm-6.218 6.135c-4.272 0-6.386 1.35-6.386 5.398v.098c0 4.048 2.114 5.398 6.386 5.398 3.255 0 6.204-.46 6.204-2.296V8.417c.014-1.823-2.949-2.282-6.204-2.282zm34.85 5.37v.153c0 5.732-3.2 9.21-10.838 9.21-7.638 0-10.865-3.492-10.865-9.21v-.153c0-5.731 3.227-9.21 10.865-9.21 7.624 0 10.838 3.492 10.838 9.21zm-4.591.126v-.098c0-3.812-1.934-5.133-6.261-5.133s-6.26 1.321-6.26 5.133v.098c0 3.812 1.933 5.133 6.26 5.133 4.327.014 6.261-1.307 6.261-5.133zM14.872 20.743c-.139-.556-.626-1.071-1.21-1.725-1.586-1.753-3.604-6.233 1.655-9.057C19.964 7.471 24.792 4.438 26.628 0H11.756c.139.556.626 1.071 1.21 1.725 1.586 1.753 3.604 6.233-1.655 9.057C6.664 13.272 1.822 16.305 0 20.743h14.872z" />
        </g>
      </svg>
    </Wrapper>
  );
}

export default LogoFull;
