import React from 'react';
import styled, { withTheme } from 'styled-components';

import Head from 'next/head';
import Header from './Header';
import Footer from './Footer';
import { DOMAIN } from '../../config';
import SignUpForm from '@components/Form/SignUpForm';
import LoadingScreen from '@components/UI/LoadingScreen';
import { setupLegacyAmplitude } from '../../tracking/amplitude';

const PageLayout = styled.div`
  /* overflow: hidden; -> removing breaks position sticky can't default this behavior for all pages */
  overflow: ${(props) => (props.disableOverflow ? 'visible' : 'hidden')};
`;

const Main = styled.div``;

const SignUpFormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const Layout = ({
  children,
  pageTitle,
  description,
  section,
  isDarkMode,
  useTransparentHeader,
  hasBorder,
  background,
  banner,
  theme,
  disableOverflow,
}) => {
  const { isMobile } = theme;

  const [showSignUpModal, setShowSignUpModal] = React.useState(false);
  const [isLeavingSite, setIsLeavingSite] = React.useState(false);
  const [urlParams, setUrlParams] = React.useState('');

  React.useEffect(() => {
    setupLegacyAmplitude();
  }, []);

  const leaveSite = () => {
    setIsLeavingSite(true);
  };

  React.useEffect(() => {
    const {
      location: { href },
    } = window;
    const params = href.split('?');
    if (params.length && params[1]) {
      setUrlParams(`?${params[1]}`);
    }
  }, []);

  const onShowSignUpModal = () => {
    setShowSignUpModal(true);
  };
  const onHideSignUpModal = () => {
    setShowSignUpModal(false);
  };

  React.useEffect(() => {
    if (window?.fbq && typeof window.fbq === 'function') {
      window?.fbq('track', 'PageView');
    } else {
      console.log('skipping fbq init not found');
    }
    if (window?.ga && typeof window.ga === 'function') {
      window?.ga('send', 'pageview', window.location.pathname);
    }
  });

  return (
    <PageLayout disableOverflow={disableOverflow}>
      <Head>
        <title>{pageTitle || 'Tornado.com | Invest better than yesterday'}</title>
        <meta
          name="Description"
          content={
            description ||
            'Tornado gives you the insights & community to turn your instincts into smart investing. Join today to sharpen your skills & grow your gains.'
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://tornado.com/" />
        <meta property="og:title" content="Tornado.com | Invest better than yesterday" />
        <meta
          property="og:description"
          content="Tornado gives you the insights & community to turn your instincts into smart investing."
        />
        <meta property="og:image" content="https://tornado.com/images/share-card.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://tornado.com/" />
        <meta property="twitter:title" content="Tornado.com | Invest better than yesterday" />
        <meta
          property="twitter:description"
          content="Tornado gives you the insights & community to turn your instincts into smart investing."
        />
        <meta property="twitter:image" content="https://tornado.com/images/share-card.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!process.env.ENVIRONMENT === 'production' && <meta name="robots" content="noindex, nofollow" />}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta charSet="utf-8" />
        <meta name="apple-itunes-app" content="app-id=1481266478, app-argument=nvstr" />
        <meta property="og:title" content="Smarter, Social Investing" />
        <meta property="og:description" content="Harness the power of technology and collective insights" />
        <meta property="og:url" content={DOMAIN} />
        <link rel="icon" href="/favicon-260.png" />
        <link rel="preload" href="/fonts/Bolton-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Bolton-Medium.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Bolton-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      </Head>

      {isMobile === null ? null : (
        <>
          {banner}
          {background}
          <Main>
            <Header
              section={section}
              isDarkMode={isDarkMode}
              hasBorder={hasBorder}
              useTransparentHeader={useTransparentHeader}
              urlParams={urlParams}
              showSignUpModal={showSignUpModal}
              onShowSignUpModal={onShowSignUpModal}
            />
            {children}

            {showSignUpModal ? (
              <SignUpFormWrapper>
                <SignUpForm
                  isMobile={true}
                  leaveSite={leaveSite}
                  urlParams={urlParams}
                  setShowSignUpModal={setShowSignUpModal}
                  onShowSignUpModal={onShowSignUpModal}
                  onHideSignUpModal={onHideSignUpModal}
                />
              </SignUpFormWrapper>
            ) : null}
            {isLeavingSite ? <LoadingScreen /> : null}
          </Main>
          <Footer />
        </>
      )}
    </PageLayout>
  );
};

export default withTheme(Layout);
