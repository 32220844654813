import { logAmplitudeEvent } from '../amplitude';

const viewFAQPage = {
  send: (properties) => {
    const event = 'View FAQ Page';
    logAmplitudeEvent(event, properties);
  },
};

const expandSection = {
  send: (properties) => {
    // Section
    // Section Name
    const event = 'FAQ Section Clicked';
    logAmplitudeEvent(event, properties);
  },
};

const expandQuestion = {
  send: (properties) => {
    // Question
    // ID
    const event = 'FAQ Question Expanded';
    logAmplitudeEvent(event, properties);
  },
};

const trackingEvents = {
  viewFAQPage,
  expandSection,
  expandQuestion,
};

export default trackingEvents;
