import { logAmplitudeEvent } from '../amplitude';

const view404 = {
  send: (properties) => {
    const event = 'View 404 Error Page';
    logAmplitudeEvent(event, properties);
  },
};

const view500 = {
  send: (properties) => {
    const event = 'View 500 Error Page';
    logAmplitudeEvent(event, properties);
  },
};

const clickDownloadInAppStore = {
  send: (properties) => {
    const event = 'Click Download In App Store';
    logAmplitudeEvent(event, properties);
  },
};

const clickGetOnGooglePlay = {
  send: (properties) => {
    const event = 'Click Get on Google Play';
    logAmplitudeEvent(event, properties);
  },
};

const clickFooterLink = {
  send: (properties) => {
    const event = 'Click Footer Link';
    logAmplitudeEvent(event, properties);
  },
};

const trackingEvents = {
  view404,
  view500,
  clickDownloadInAppStore,
  clickGetOnGooglePlay,
  clickFooterLink,
};

export default trackingEvents;
