import React from 'react';
import { withTheme } from 'styled-components';
import Line from '@components/Primitives/Line';

const HeaderLine = ({ isDarkMode, isLowContrast, height, theme }) => {
  const { textColor } = theme;
  const color = isDarkMode ? textColor.darkTheme : textColor.lightTheme;
  const opacity = isLowContrast ? 0.2 : 1;
  return <Line color={color} opacity={opacity} height={height} />;
};

export default withTheme(HeaderLine);
