import React from 'react';
import styled, { withTheme, css } from 'styled-components';

const standardStyles = css`
  min-width: 1000px;
  max-width: 1440px;
`;

const mobileStyles = css`
  min-width: 300px;
  max-width: 999px;
`;

const customBackground = css`
  background: ${(props) => props.background};
`;

const SectionWrap = styled.div`
  ${(props) => (props.background ? customBackground : '')};
`;

const SectionInner = styled.div`
  margin: 0 auto;
  position: relative;
  ${(props) => (props.theme.isMobile ? mobileStyles : standardStyles)};
`;

const Section = ({ children, background }) => {
  return (
    <SectionWrap background={background}>
      <SectionInner>{children}</SectionInner>
    </SectionWrap>
  );
};

export default withTheme(Section);
