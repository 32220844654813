import React from 'react';
import { withTheme } from 'styled-components';
import Link from 'next/link';
import { Row, Column, colorPalette, Place } from '@lib/nvstr-common-ui.es';
import { Body1, Bold, Eyebrow, H4 } from '@components/Text';
import { PATHS } from '@constants/paths';
import HeaderLine from '@components/UI/HeaderLine';
import LogoShort from '@components/Images/LogoShort';
import AppStore from '@components/Images/AppStore';
import GooglePlay from '@components/Images/GooglePlay';
import Anchor from '@components/Primitives/Anchor';
import SPGlobal from '@components/Images/logos/SPGlobal';
import Xignite from '@components/Images/logos/Xignite';
import Nasdaq from '@components/Images/logos/Nasdaq';
import SIPC from '@components/Images/logos/SIPC';
import VerticalLogoFull from '@components/Images/VerticalLogoFull';
import Section from '@components/Layout/Section';
import { TrackingEvents } from '../../tracking';
import { ElementPositionContext } from '../../context';
import { enums } from '@lib/nvstr-utils.es';
const { node_location } = enums;

const companyLinks = {
  heading: 'Company',
  links: [
    {
      text: 'Home',
      url: PATHS.HOME,
      name: 'Home',
    },
    {
      text: 'About',
      url: PATHS.ABOUT,
      name: 'About Us',
    },
    // {
    //   text: 'Enterprise',
    //   url: PATHS.ENTERPRISE,
    //   name: Enterprise Page,
    // },
    {
      text: 'Blog',
      url: PATHS.BLOG,
      name: 'Blog',
    },
  ],
};

const socialLinks = {
  heading: 'Social',
  links: [
    {
      text: 'LinkedIn',
      url: PATHS.LINKEDIN,
      name: 'LinkedIn',
    },
    {
      text: 'Twitter',
      url: PATHS.TWITTER,
      name: 'Twitter',
    },
    {
      text: 'Instagram',
      url: PATHS.INSTAGRAM,
      name: 'Instagram',
    },
  ],
};

const resourcesLinks = {
  heading: 'Resources',
  links: [
    {
      text: 'Contact & FAQ',
      url: PATHS.CONTACT,
      name: 'Contact/FAQ',
    },
    {
      text: 'Legal',
      url: PATHS.LEGAL,
      name: 'Legal',
    },
  ],
};

const LinkColumn = ({ linkColumnData, isDarkMode }) => {
  const { heading, links } = linkColumnData;
  const logLinkClick = (name) => {
    TrackingEvents.application.clickFooterLink.send({ Name: name });
  };
  const bindNameToLogLinkClick = (name) => () => logLinkClick(name);
  return (
    <Place width="158px">
      <Row padding={'0 0 24px 0'}>
        <Bold>
          <Eyebrow isDarkMode={isDarkMode}>{heading}</Eyebrow>
        </Bold>
      </Row>
      {links.map((link, i) => {
        return (
          <Row key={`${i}-${link.text}`} padding={i === 0 ? '0' : '14px 0 0 0'}>
            <Link href={link.url} passHref>
              <Anchor>
                <span onClick={bindNameToLogLinkClick(link.name)}>
                  <Body1 isLarge isDarkMode={isDarkMode}>
                    {link.text}
                  </Body1>
                </span>
              </Anchor>
            </Link>
          </Row>
        );
      })}
    </Place>
  );
};

const DataProviders = ({ isMobile }) => {
  return (
    <>
      <Nasdaq width={isMobile ? 65 : 138} opacity={0.4} />
      {isMobile ? (
        <Place padding="2px 0 0 0">
          <Xignite width={isMobile ? 60 : 139} opacity={0.4} />
        </Place>
      ) : (
        <Xignite width={isMobile ? 60 : 139} opacity={0.4} />
      )}
      <SIPC width={isMobile ? 36 : 70} opacity={0.4} />
      <SPGlobal width={isMobile ? 66 : 132} opacity={0.4} />
    </>
  );
};

const FooterDisclosures = ({ isDarkMode, isMobile }) => {
  const desktopTextProps = {
    fontSize: '12px',
    textCase: 'none',
    isDarkMode,
    isLowContrast: true,
    isSmall: true,
  };
  const mobileTextProps = {
    fontSize: '10px',
    textCase: 'none',
    isDarkMode,
    isLowContrast: true,
    isSmall: true,
  };
  const textProps = isMobile ? mobileTextProps : desktopTextProps;
  return (
    <Column>
      <Row>
        <Eyebrow {...textProps}>
          By using this site you agree to our{' '}
          <Anchor textDecoration="underline" href="/terms_of_service">
            Terms of Service
          </Anchor>{' '}
          and{' '}
          <Anchor textDecoration="underline" href="/privacy_policy">
            Privacy Policy
          </Anchor>
          . Nvstr Financial LLC dba Tornado ("Tornado") is a wholly owned
          subsidiary of Nvstr Technologies Inc. The contents of this site are not investment advice. Brokerage services
          are offered by Tornado. IMPORTANT: The projections or other information generated by investment
          analysis tools regarding the likelihood of various investment outcomes are hypothetical in nature, do not
          reflect actual investment results and are not guarantees of future results. Investment analysis tool output,
          Community Thought Leader content, and Thought Leader ideas are not recommendations and should not be construed
          as such. Tornado does not endorse Thought Leaders, Community Thought Leaders, their content, or
          their positions in any way. A Thought Leader or Community Thought Leader&apos;s investment approach may be
          very different from yours, and their positions may not be appropriate for you. Thought Leader ideas are
          sourced from public information regarding a firm&apos;s recent positions. Please refer to the Terms of Service
          for more information. Investing in stocks is inherently risky; using margin may increase these risks.
        </Eyebrow>
      </Row>
      <Row padding="15px 0 0 0">
        <Eyebrow {...textProps}>© 2022 Nvstr Technologies Inc. All rights reserved. The Tornado platform, including the functionality of its associated web page(s) and app(s), is protected under at least US Patent No. 11,138,667 as well as other patents pending.</Eyebrow>
      </Row>
      <Row padding="15px 0 0 0">
        <Eyebrow {...textProps}>
          Tornado’s data is sourced from S&P Global Market Intelligence, Nasdaq and Xignite. Tornado is a member of{' '}
          <Anchor textDecoration="underline" href="https://www.finra.org/">
            FINRA
          </Anchor>{' '}
          and{' '}
          <Anchor textDecoration="underline" href="https://www.sipc.org/">
            SIPC
          </Anchor>
          . Further information is available through{' '}
          <Anchor textDecoration="underline" href="https://brokercheck.finra.org/firm/summary/269966">
            FINRA’s BrokerCheck website
          </Anchor>{' '}
          and our{' '}
          <Anchor textDecoration="underline" href="https://api.tornado.com/client_relationship_summary">
            Form CRS
          </Anchor>
          .
        </Eyebrow>
      </Row>
    </Column>
  );
};

const DesktopFooter = ({ isDarkMode }) => {
  const showTextAppLink = false;

  const handleAppStoreClick = () => {
    TrackingEvents.application.clickDownloadInAppStore.send();
  };
  const handleGooglePlayClick = () => {
    TrackingEvents.application.clickGetOnGooglePlay.send();
  };

  return (
    <Column padding="122px 90px 118px 90px" position="relative" overflow="hidden">
      <Place position="absolute" top="28px" left="4px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="-730px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="680px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>

      <Place zIndex={10}>
        <Row padding={'0 0 50px 0'} style={{ justifyContent: 'space-between' }}>
          <Column width="460px">
            <Link href={PATHS.HOME} passHref>
              <Anchor>
                <LogoShort isDarkMode={isDarkMode} height={51} width={59} />
              </Anchor>
            </Link>
            <Row
              width={'100%'}
              padding="57px 0 0 0"
              wrap={'wrap'}
              style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <Place margin="0 16px 16px 0">
                <Link href={PATHS.APP_STORE} passHref>
                  <Anchor>
                    <span onClick={handleAppStoreClick}>
                      <AppStore width={'204px'} height={'60px'} isDarkMode={isDarkMode} />
                    </span>
                  </Anchor>
                </Link>
              </Place>
              <Place margin="0 28px 24px 0">
                <Link href={PATHS.GOOGLE_PLAY} passHref>
                  <Anchor>
                    <span onClick={handleGooglePlayClick}>
                      <GooglePlay width={'204px'} height={'60px'} isDarkMode={isDarkMode} />
                    </span>
                  </Anchor>
                </Link>
              </Place>
            </Row>
            {showTextAppLink && (
              <>
                <Row padding={'64px 0 0 0'}>
                  <Eyebrow isDarkMode={isDarkMode}>JOIN NOW</Eyebrow>
                </Row>
                <Row padding={'36px 0 0 0'}>
                  <Eyebrow isDarkMode={isDarkMode}>TEXT ME THE APP</Eyebrow>
                </Row>
              </>
            )}
          </Column>

          <Place>
            <Row width="540px" margin="0 90px 0 0px" padding="0 0 0 0">
              <H4 isDarkMode={isDarkMode}>
                <Bold>
                  INVEST
                  <br />
                  BETTER
                </Bold>{' '}
                THAN
                <br />
                YESTERDAY
              </H4>
            </Row>
            <Column>
              <Row maxWidth="620px" padding="85px 0 0 0" wrap="wrap" style={{ justifyContent: 'space-between' }}>
                <Place padding="0 0 24px 0">
                  <LinkColumn isDarkMode={isDarkMode} linkColumnData={companyLinks} />
                </Place>
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={socialLinks} />
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={resourcesLinks} isLastItem />
              </Row>
            </Column>
          </Place>
        </Row>
        <Row>
          <HeaderLine isDarkMode />
        </Row>
        <Row padding={'82px 0 0 0'}>
          <FooterDisclosures isDarkMode={isDarkMode} />
        </Row>
        <Row padding={'88px 0 0 0'} style={{ justifyContent: 'space-between' }}>
          <DataProviders />
        </Row>
      </Place>
    </Column>
  );
};

export const MobileFooter = ({ isDarkMode }) => {
  const showTextAppLink = false;

  const handleAppStoreClick = () => {
    TrackingEvents.application.clickDownloadInAppStore.send();
  };
  const handleGooglePlayClick = () => {
    TrackingEvents.application.clickGetOnGooglePlay.send();
  };

  return (
    <Column padding="92px 7.5% 70px 7.5%" position="relative" overflow="hidden">
      <Place position="absolute" top="28px" left="4px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="-730px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>
      <Place position="absolute" top="680px" left="168px" zIndex={1}>
        <VerticalLogoFull />
      </Place>

      <Place position="relative" zIndex={10}>
        <Column>
          <Row>
            <Link href={PATHS.HOME} passHref>
              <Anchor>
                <LogoShort width="59px" height="51px" isDarkMode={isDarkMode} />
              </Anchor>
            </Link>
          </Row>
          <Row padding="32px 0 0 0">
            <H4 isDarkMode={isDarkMode}>
              <Bold>
                INVEST
                <br />
                BETTER
              </Bold>{' '}
              THAN
              <br />
              YESTERDAY
            </H4>
          </Row>
          <Row padding="72px 0 0 0" maxWidth="424px" wrap="wrap" style={{ justifyContent: 'space-between' }}>
            <Place padding="0 16px 16px 0">
              <Link href={PATHS.APP_STORE} passHref>
                <Anchor>
                  <span onClick={handleAppStoreClick}>
                    <AppStore width={'204px'} height={'60px'} isDarkMode={isDarkMode} />
                  </span>
                </Anchor>
              </Link>
            </Place>
            <Place margin="0 0 0 0">
              <Link href={PATHS.GOOGLE_PLAY} passHref>
                <Anchor>
                  <span onClick={handleGooglePlayClick}>
                    <GooglePlay width={'204px'} height={'60px'} isDarkMode={isDarkMode} />
                  </span>
                </Anchor>
              </Link>
            </Place>
          </Row>
          {showTextAppLink && (
            <>
              <Row padding={'72px 0 0 0'}>
                <Eyebrow isDarkMode={isDarkMode}>JOIN NOW</Eyebrow>
              </Row>
              <Row padding={'36px 0 0 0'}>
                <Eyebrow isDarkMode={isDarkMode}>TEXT ME THE APP</Eyebrow>
              </Row>
            </>
          )}
          <Column>
            <Row padding="68px 0 0 0" width="100%" wrap="wrap">
              <Place padding="0 0 68px 0">
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={companyLinks} />
              </Place>
              <Place padding="0 0 68px 0">
                <LinkColumn isDarkMode={isDarkMode} linkColumnData={socialLinks} />
              </Place>

              <LinkColumn isDarkMode={isDarkMode} linkColumnData={resourcesLinks} />
            </Row>
          </Column>
          <Row padding={'64px 0 0 0'}>
            <HeaderLine isDarkMode={isDarkMode} />
          </Row>
          <Row padding={'64px 0 0 0'}>
            <FooterDisclosures isDarkMode={isDarkMode} isMobile />
          </Row>
          <Row padding={'60px 0 0 0'} style={{ justifyContent: 'space-between' }}>
            <DataProviders isMobile />
          </Row>
        </Column>
      </Place>
    </Column>
  );
};

const Footer = ({ theme }) => {
  const { isMobile } = theme;
  const isDarkMode = true;
  return (
    <ElementPositionContext.Provider value={{ elementPosition: node_location.footer }}>
      <Section background={colorPalette.primary.charcoal}>
        <HeaderLine isDarkMode={isDarkMode} isLowContrast height={'2px'} />
        {isMobile ? <MobileFooter isDarkMode={isDarkMode} /> : <DesktopFooter isDarkMode={isDarkMode} />}
      </Section>
    </ElementPositionContext.Provider>
  );
};

export default withTheme(Footer);
