import React from 'react';
import styled, { withTheme } from 'styled-components';
import BaseButton from './BaseButton';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const Wrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button1 = (props) => {
  const { theme, children } = props;
  return (
    <BaseButton
      background={theme.secondary.purple}
      hoverBackground={theme.secondary.white}
      borderHoverColor={theme.secondary.purple}
      color={theme.secondary.white}
      hoverColor={theme.primary.charcoal}
      buttonType={button_types.button}
      {...props}
    >
      <Wrapper>{children}</Wrapper>
    </BaseButton>
  );
};

export default withTheme(Button1);
