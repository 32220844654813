import styled from 'styled-components';
import { Column } from '@lib/nvstr-common-ui.es';
import useButtonEvent from '@utils/itly/hooks/useButtonEvent';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const StyledBaseButton = styled(Column).attrs(() => ({
  hAlign: 'center',
  vAlign: 'center',
}))`
  border-radius: ${(props) => props.borderRadius || '4px'};
  cursor: pointer;
  user-select: none;
  width: ${(props) => props.width || '200px'};
  height: ${(props) => props.height || '64px'};
  background: ${(props) => props.background || ''};
  border: 2px solid ${(props) => props.borderColor || props.background};
  transition: background-color 300ms, color 300ms;
  div,
  span {
    color: ${(props) => props.color};
  }
  &:hover {
    background: ${(props) => props.hoverBackground || ''};
    border: 2px solid ${(props) => props.borderHoverColor || props.background};
    div,
    span {
      color: ${(props) => props.hoverColor};
    }
  }
`;

const BaseButton = (props) => {
  const { onClick } = useButtonEvent(props);
  return <StyledBaseButton {...props} onClick={onClick} />;
};

BaseButton.defaultProps = {
  buttonType: button_types.button,
};

export default BaseButton;
