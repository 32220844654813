import application from './events/application';
import faq from './events/faq';
import splash from './events/splash';
import aboutUs from './events/aboutUs';

export const TrackingEvents = {
  application,
  splash,
  aboutUs,
  faq,
};
