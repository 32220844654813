import { logAmplitudeEvent } from '../amplitude';

const viewHomepage = {
  send: (properties) => {
    const event = 'View Splash';
    logAmplitudeEvent(event, properties);
  },
};

const clickGetStartedButton = {
  send: (properties) => {
    // Context: <context>
    const event = 'Clicked Splash Signup Button';
    logAmplitudeEvent(event, properties);
  },
};

const clickLearnMoreButton = {
  send: (properties) => {
    const event = 'Clicked Learn More About Tornado';
    logAmplitudeEvent(event, properties);
  },
};

const trackingEvents = {
  viewHomepage,
  clickGetStartedButton,
  clickLearnMoreButton,
};

export default trackingEvents;
