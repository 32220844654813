import amplitude from 'amplitude-js';

const LOG_TRACKING_EVENTS = true;

let isLegacyAmplitudeSetup = false;

export const setupLegacyAmplitude = () => {
  const apiKey = getAmplitudePublicAPIKey();

  if (isLegacyAmplitudeSetup) {
    return;
  }
  isLegacyAmplitudeSetup = true;

  try {
    if (process.browser) {
      amplitude.getInstance('legacyEvents').init(apiKey);
    }
  } catch (e) {
    console.warn('could not setup amplitude');
    console.error(e);
  }
};

const _getCurrentAmplitudeInstance = () => {
  try {
    return amplitude.getInstance('legacyEvents');
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getAmplitudePublicAPIKey = () => {
  let PUBLIC_AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY;
  if (!PUBLIC_AMPLITUDE_KEY) {
    PUBLIC_AMPLITUDE_KEY = '5d38cf63e73716fbf73bbe6811283360';
  }
  return PUBLIC_AMPLITUDE_KEY;
};

export const setAmplitudeUserProperties = (userProperties) => {
  // console.log('setting user properties', userProperties);

  const instance = _getCurrentAmplitudeInstance();
  instance?.setUserProperties(userProperties);
};

export const logAmplitudeEvent = (eventName, properties = {}) => {
  const loggerCallback = (responseCode, responseBody) => {
    if (LOG_TRACKING_EVENTS) {
      console.log(`${eventName} event sent`, {
        properties,
        responseCode,
        responseBody,
      });
    }
  };

  const instance = _getCurrentAmplitudeInstance();
  instance?.logEvent(eventName, properties, loggerCallback);
};
