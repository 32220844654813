import React from 'react';
import styled from 'styled-components';
import { Row, Column, Place, Modal, LoadingSpinner, colorPalette } from '@lib/nvstr-common-ui.es';
import { Button1 } from '@components/Buttons';
import { signUpEmail } from '@utils/services';
import { WEB_URL } from '../../config';

const A = styled.a`
  display: flex;
  text-decoration: none;
`;

const WrapperEmbed = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
`;

const SpinnerWrap = styled.div`
  width: 80px;
  margin: 80px auto 0 auto;
`;

const EmbeddedSpinnerWrap = styled.div`
  svg {
    height: 30px;
    width: 30px;
  }

  div {
    margin: 0;
    height: 30px;
    width: 30px;
    vertical-align: unset;
  }
`;

const Title = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
`;

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const InputContainer = styled.fieldset`
  position: relative;

  height: ${({ isEmbedded }) => (isEmbedded ? '53px' : 'auto')};
  padding: ${({ isEmbedded, isLoading }) => (isLoading ? '0' : isEmbedded ? '16px 12px' : '12px 10px 12px 0')};

  background: ${({ isMobile, isEmbedded }) => (isMobile || isEmbedded ? '#fff' : '')};
  border-radius: ${({ isEmbedded }) => (isEmbedded ? '6px' : '')};
`;

const InputField = styled.input`
  border-bottom: ${({ isEmbedded }) => (isEmbedded ? 'none' : '1px solid #222')};
  padding: ${({ isEmbedded }) => (isEmbedded ? '0' : '8px 0')};

  &:focus {
    outline: none;
  }
`;

const AlreadyHaveAccount = styled.div`
  padding: 0 0 20px 0;
  font-size: 14px;
`;

const LoginText = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

export const ValidationWrap = styled.div`
  position: absolute;
  top: ${(props) => (props.isEmbedded ? '-24px' : '96px')};
`;

export const ValidationText = styled.span`
  color: ${colorPalette.secondary.red};
`;

export const ModalContent = styled.div`
  padding-top: 50px;
`;

const EmbeddedForm = ({ isLoading, validationMessage, handleInputChange, handleSignUp, emailValue }) => {
  const [buttonEventProperties] = React.useState({ 'Is Sign Up': true });
  const handleChange = (e) => handleInputChange(e.target.value);

  return (
    <WrapperEmbed>
      <InputContainer isEmbedded isLoading={isLoading}>
        {isLoading ? (
          <Row height="100%" width="100%" vAlign="center" hAlign="center">
            <EmbeddedSpinnerWrap>
              <LoadingSpinner />
            </EmbeddedSpinnerWrap>
          </Row>
        ) : (
          <>
            {validationMessage !== null && (
              <Place width="100%" zIndex={10} position="absolute" top="-28px">
                <div style={{ textAlign: 'center' }}>
                  <ValidationText>{validationMessage}</ValidationText>
                </div>
              </Place>
            )}
            <InputField
              isEmbedded={true}
              placeholder="Email"
              type="email"
              value={emailValue}
              onChange={handleChange}
              autoComplete="email"
            />
            <Place position="absolute" top="5px" right="6px">
              <Button1 height="39px" width="120px" onClick={handleSignUp} buttonEventProperties={buttonEventProperties}>
                GET STARTED
              </Button1>
            </Place>
          </>
        )}
      </InputContainer>
    </WrapperEmbed>
  );
};

const StandaloneForm = ({
  isLoading,
  validationMessage,
  handleInputChange,
  handleSignUp,
  handleCloseModal,
  emailValue,
  urlParams,
}) => {
  const [buttonEventProperties] = React.useState({ 'Is Sign Up': true });

  const handleChange = (e) => handleInputChange(e.target.value);

  return (
    <Modal isAnimated onDismiss={handleCloseModal}>
      <ModalContent>
        <Title>Get Started in Minutes</Title>
        {isLoading && (
          <SpinnerWrap>
            <LoadingSpinner />
          </SpinnerWrap>
        )}
        {!isLoading && (
          <Column padding="50px 0 0 0">
            {validationMessage !== null && (
              <ValidationWrap>
                <ValidationText>{validationMessage}</ValidationText>
              </ValidationWrap>
            )}

            <InputLabel>Email</InputLabel>
            <InputField
              placeholder={'Enter Your Email...'}
              type="email"
              value={emailValue}
              onChange={handleChange}
              autoComplete="email"
            />
            <Column hAlign="center" padding="50px 0 0 0">
              <Place padding="0 0 20px">
                <Button1 width="260px" onClick={handleSignUp} buttonEventProperties={buttonEventProperties}>
                  CONTINUE
                </Button1>
              </Place>
              <AlreadyHaveAccount>Already have an account?</AlreadyHaveAccount>
              <A href={`${WEB_URL}/login${urlParams}`}>
                <LoginText>LOG IN</LoginText>
              </A>
            </Column>
          </Column>
        )}
      </ModalContent>
    </Modal>
  );
};

const SignUpForm = ({ setShowSignUpModal, urlParams = '', isEmbedded }) => {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState('');

  const userSignUpComplete = () => {
    window.location = `/app/signup?email=${encodeURIComponent(email)}`;
  };

  const closeModal = () => {
    setShowSignUpModal(false);
  };

  const userSignUpError = (errorMessage) => {
    setIsLoading(false);
    setValidationMessage(errorMessage);
  };

  const userSignUp = (e) => {
    const landerSlug = window.location.pathname;

    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);

    if (email.trim() !== '') {
      signUpEmail({
        email,
        userSignUpComplete,
        userSignUpError,
        landerSlug,
      });
    } else {
      setIsLoading(false);
      setValidationMessage('Please enter your email.');
    }
  };

  return isEmbedded ? (
    <EmbeddedForm
      isLoading={isLoading}
      validationMessage={validationMessage}
      emailValue={email}
      handleInputChange={setEmail}
      handleSignUp={userSignUp}
    />
  ) : (
    <StandaloneForm
      isLoading={isLoading}
      validationMessage={validationMessage}
      emailValue={email}
      handleInputChange={setEmail}
      handleSignUp={userSignUp}
      handleCloseModal={closeModal}
      urlParams={urlParams}
    />
  );
};

export default SignUpForm;
